import TableOnly from "components/DataTable/TableOnly";
import Pagination from "components/Pagination/Pagination";
import QuotePdfButton from "components/Pdfs/QuotePdf/QuotePdfButton";
import SearchComp from "components/SearchComp/SearchComp";
import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";
import QuoteAdd from "./QuoteAdd";

function Quote() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.userInfo);
  const [searchParams, setSearchParams] = useSearchParams();

  const params = useParams();
  const navigate = useNavigate();

  /*   All States
   ********************************************* */
  const [quote, setQuote] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);

  const [active, setActive] = useState(null);

  const [totalPages, setTotalPages] = useState(1);

  const [filterBy, setFilterBy] = useState(null);

  const [isLoading, setIsLoading] = useState(null);

  /*   All Functions
   ********************************************* */
  const getQuote = async (activeTab, filterBy) => {
    setIsLoading(true);

    try {
      let page = searchParams.get("page");
      let query = searchParams.get("query");

      let body = { page, activeTab, filterBy };

      if (query) {
        body.query = query;
      }

      const { data } = await API.post("/quotes", body, {
        headers: authHeader(),
      });
      setQuote(data.result);
      setTotalPages(data.pages);
      setIsLoading(false);
      return data.result;
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setIsLoading(false);
    }
  };

  // Delete Quote
  const deleteWorkOrder = async (id) => {
    setIsLoading(true);
    try {
      const { data } = await API.delete("quotes/delete/" + id, {
        headers: authHeader(),
      });
      if (data?.message?.type === "success") {
        await getQuote(active, filterBy);
        return;
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setIsLoading(false);
    }
  };

  // Function to update query param "filter-by-type"
  const updateFilterByType = (value) => {
    if (value === null) {
      searchParams.delete("filter-by-type");
    } else {
      searchParams.set("filter-by-type", value);
    }
    setSearchParams(searchParams);
  };

  // Example usage of the function
  const handleFilterChange = (filterValue) => {
    updateFilterByType(filterValue);
    setFilterBy(filterValue);
  };

  // Example usage of the function

  // Getting 12 Hours Time from SQL timestamp
  const convertSqlTimestampToDate = (sqlTime) => {
    const timestamp = sqlTime;
    const date = new Date(timestamp);
    const time = date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return time;
  };

  /*   Table Functions
   ********************************************* */
  const btn1 = () => (
    <div>
      {(user?.permissions?.includes("quote_add") ||
        user?.roles === "admin") && (
        <div className="d-flex justify-content-end align-items-center">
          <button
            type="button"
            className="px-1 bg-primary btn text-dark bg-opacity-10 border border-primary rounded-end mx-2 rounded-start"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {filterBy
              ? filterBy.toUpperCase().replaceAll("_", " ")
              : "Filter by All"}
          </button>
          <div className="dropdown-menu">
            <button
              onClick={() => handleFilterChange(null)}
              className="dropdown-item"
            >
              All
            </button>
            <button
              onClick={() => handleFilterChange("quote")}
              className="dropdown-item"
            >
              Quote
            </button>
            <button
              onClick={() => handleFilterChange("bid")}
              className="dropdown-item"
            >
              Bid
            </button>
          </div>
          <button
            onClick={() => setShowAddModal(true)}
            type="button"
            className="btn btn-primary  ms-1"
          >
            ADD Quote
          </button>
          {/* <button onClick={TestFunc} className="btn btn-success ms-1">
            Test Func
          </button> */}
        </div>
      )}
    </div>
  );

  const table_head = [
    { name: "Quote ID", value: "id" },
    { name: "Customer Name", value: "customer.customerName" },
    { name: "Job Name", value: "jobName" },
    { name: "Type", value: "bidId" },
    { name: "Location", value: "location.name" },
    { name: "Created Date", value: "createdAt" },
    { name: "Status", value: "status" },
    { name: "Actions", value: "Actions" },
  ];

  const table_body = (item, index) => (
    <tr className="" key={item.id}>
      <td className="py-1 align-middle me-0">{item?.quoteId}</td>
      <td className="py-1 align-middle me-0">
        {item?.customer?.customerName?.slice(0, 30)}
      </td>
      <td className="py-1 align-middle me-0">{item?.jobName?.slice(0, 30)}</td>
      <td className="py-1 align-middle me-0">
        {item?.bidId ? "Bid" : "Quote"}
      </td>
      <td className="py-1 align-middle me-0">{item?.location?.name}</td>
      <td className="py-1 align-middle me-0">
        {convertSqlTimestampToDate(item.createdAt)}{" "}
      </td>

      <td className="py-1 align-middle me-0">{item?.status}</td>
      <td className="table-action d-flex py-1 align-middle me-0">
        <QuotePdfButton btnClasses={"btn btn-success"} selectedQuote={item} />
        <button
          onClick={() => {
            navigate(
              `/quotes/details/${active}/${item?.id}?${searchParams.toString()}`
            );
          }}
          className={`btn btn-sm bg-success text-white mx-1 `}
        >
          Details
        </button>

        <button
          onClick={() => {
            deleteWorkOrder(item?.id);
          }}
          className="btn btn-sm btn-danger"
        >
          <i className="mdi mdi-trash-can"></i>
        </button>
      </td>
    </tr>
  );

  /*   All Use Effects
   ********************************************* */

  useEffect(() => {
    if (params?.filterBy) {
      setActive(params?.filterBy);
    } else {
      setActive("pending-processing");
    }
  }, [params]);

  useEffect(() => {
    let paramsUrl = "";
    searchParams.forEach((value, key) => {
      paramsUrl += `${key}=${value}&`;
    });

    let filterByType = searchParams.get("filter-by-type");

    if (active) {
      getQuote(active, filterByType);
    }
  }, [active, searchParams]);

  useEffect(() => {
    let filterByType = searchParams.get("filter-by-type");
    setFilterBy(filterByType);
  }, [searchParams]);

  return (
    <div className="row">
      <Toast />
      {quote ? (
        <div className="col-12">
          <div className="d-flex">
            {/*    Table Card
             ********************************************* */}
            <div className=" flex-grow-1">
              <div
                className={`flex-grow-1 d-flex flex-column bg-white shadow-lg px-5 py-2`}
                style={{
                  position: "fixed",
                  top: 0,
                  width: "calc(100vw - 200px)",
                  marginLeft: 200,
                  zIndex: "99",
                }}
              >
                <ul className="nav nav-tabs nav-bordered border-0 d-flex flex-grow-1">
                  <li
                    onClick={() => {
                      let url = "/quotes/pending-processing?page=1";
                      if (filterBy) {
                        url += `&filter-by-type=${filterBy}`;
                      }
                      navigate(url);
                    }}
                    className="nav-item"
                  >
                    <button
                      className={
                        active === "pending-processing"
                          ? `nav-link rounded-0 active bg-primary text-white`
                          : "nav-link rounded-0"
                      }
                    >
                      <span className="d-none d-md-block">
                        Pending & Processing
                      </span>
                    </button>
                  </li>

                  <li
                    onClick={() => {
                      let url = "/quotes/completed?page=1";
                      if (filterBy) {
                        url += `&filter-by-type=${filterBy}`;
                      }
                      navigate(url);
                    }}
                    className="nav-item"
                  >
                    <button
                      className={
                        active === "completed"
                          ? `nav-link rounded-0 active bg-primary text-white`
                          : "nav-link rounded-0"
                      }
                    >
                      <span className="d-none d-md-block">COMPLETED</span>
                    </button>
                  </li>
                  <li
                    onClick={() => {
                      let url = "/quotes/all?page=1";
                      if (filterBy) {
                        url += `&filter-by-type=${filterBy}`;
                      }
                      navigate(url);
                    }}
                    className=""
                  >
                    <button
                      className={
                        active === "all"
                          ? `nav-link rounded-0 active bg-primary text-white`
                          : "nav-link rounded-0"
                      }
                    >
                      <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                      <span className="d-none d-md-block">ALL</span>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="">
                <>
                  <div
                    className=" px-3 py-2 pb-2 horizontal-scrollable py-4 d-flex flex-column flex-grow-1 mt-5"
                    style={{ borderRadius: 10, marginLeft: 260 }}
                  >
                    <div className="d-flex justify-content-end align-items-center mb-3">
                      <div className="me-1">{btn1()}</div>
                      <SearchComp
                        activeTab={active}
                        setLoading={setIsLoading}
                        setTotalPages={setTotalPages}
                        searchResults={searchResults}
                        setSearchResults={setSearchResults}
                        apiPath={`/quotes/${active}?${
                          filterBy ? "filter-by-type=" + filterBy + "&" : ""
                        }`}
                      />
                    </div>
                    {searchResults ? (
                      <TableOnly
                        table_head={table_head}
                        table_body={table_body}
                        table_data={searchResults}
                        totalPages={totalPages}
                      />
                    ) : (
                      <TableOnly
                        table_head={table_head}
                        table_body={table_body}
                        table_data={quote}
                        totalPages={totalPages}
                      />
                    )}
                    {isLoading && <Spinner />}
                    {!searchResults && (
                      <Pagination
                        totalPages={totalPages}
                        apiPath={`/quotes/${active}?${
                          filterBy ? "filter-by-type=" + filterBy + "&" : ""
                        }page=`}
                        currPage={parseInt(searchParams.get("page")) || 1}
                      />
                    )}
                  </div>
                </>
              </div>
            </div>

            {/*   ADD Modal
             ********************************************* */}
            <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
              <Modal.Body>
                <div>
                  <QuoteAdd showAddModal={setShowAddModal} />
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default Quote;
