import { BlobProvider } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { QuotePdf } from "./QuotePdf";
import API from "services/axios";
import authHeader from "services/auth-header";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setNotification } from "features/Notification/notificationSlice";
import Spinner from "components/Spinner";

export default function QuotePdfButton({ btnClasses, selectedQuote }) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [showPdf, setShowPdf] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [locationLogo, setLocationLogo] = useState(null);
  const [pdfOptions, setPdfOptions] = useState({ show_Total: true });

  const [emailTo, setEmailTo] = useState("");

  const [loading, setLoading] = useState(false);

  /*   All Functions
   ********************************************* */

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const getLocationLogo = async () => {
    const { data } = await API.post(
      "/design-request/get-image",
      { path: selectedQuote?.location?.pdfLogo },
      {
        headers: authHeader(),
      }
    );
    setShowPdf(true);
    setLocationLogo(data);
    return data;
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    console.log(selectedQuote);

    setEmailTo(selectedQuote?.contactEmail);
  }, [selectedQuote]);
  return (
    <>
      <button
        className={btnClasses}
        onClick={() => {
          setShowPdfModal(true);
        }}
      >
        Get Pdf
      </button>

      {/*   Delete Modal
       ********************************************* */}
      <Modal
        show={showPdfModal}
        onHide={() => setShowPdfModal(false)}
        contentClassName="bg-transparent"
      >
        <Modal.Body className="custom-border-radius-sm bg-white">
          <h3>Select Pdf Options</h3>
          {loading && <Spinner />}
          <hr />
          <div className="my-4">
            {Object.keys(pdfOptions)?.map((option) => (
              <div key={option} className="d-flex gap-2">
                <input
                  onChange={(e) => {
                    setPdfOptions((prev) => {
                      return { ...prev, [option]: e.target.checked };
                    });
                  }}
                  type="checkbox"
                  checked={pdfOptions[option]}
                  id={"invoicePage"}
                  data-switch="success"
                />
                <label
                  htmlFor={"invoicePage"}
                  data-on-label="Yes"
                  data-off-label="No"
                ></label>
                <div className="ms-2 fw-bold">
                  {option?.replaceAll("_", " ")?.toUpperCase()}
                </div>
              </div>
            ))}
          </div>

          {/* Email To input field */}
          <div>
            <label htmlFor="emailTo">Email To</label>
            <input
              value={emailTo}
              onChange={(e) => setEmailTo(e.target.value)}
              className="form-control"
              placeholder="Enter email address"
            />
            {!emailTo && (
              <small className="text-danger">
                Email is required ( Please email to quote details )
              </small>
            )}
          </div>

          <hr />
          <div className="d-flex justify-content-end gap-1 ">
            <button
              onClick={() => {
                setShowPdfModal(false);
              }}
              className="btn btn-secondary"
            >
              Close
            </button>
            {showPdf && locationLogo ? (
              <>
                <BlobProvider
                  document={
                    <QuotePdf
                      selectedQuote={selectedQuote}
                      locationLogo={locationLogo}
                      showTotal={pdfOptions.show_Total}
                    />
                  }
                >
                  {({ url, blob }) => {
                    const sendEmail = async () => {
                      if (!emailTo) {
                        return alert("Smtp Email is required!");
                      }
                      loadingOn();
                      const formData = new FormData();
                      formData.append("file", blob);
                      formData.append("quoteId", selectedQuote?.quoteId);
                      formData.append("emailTo", emailTo);
                      formData.append(
                        "locationName",
                        selectedQuote?.location?.name
                      );

                      try {
                        const { data } = await API.post(
                          "/quote-email",
                          formData,
                          {
                            headers: {
                              ...authHeader(),
                              "Content-Type": "multipart/form-data",
                            },
                          }
                        );
                        loadingOff();
                        if (data?.type === "success") {
                          dispatch(
                            setNotification({
                              message: data.message,
                              type: "success",
                            })
                          );
                          loadingOff();
                          setShowPdfModal(false);
                          return;
                        }
                        if (data?.type === "error") {
                          dispatch(
                            setNotification({
                              message: data.message,
                              type: "error",
                            })
                          );
                          loadingOff();
                          setShowPdfModal(false);
                          return;
                        }
                        dispatch(
                          setNotification({
                            message: "Something Went wrong check console.",
                            type: "error",
                          })
                        );
                        console.log(data);
                        loadingOff();
                      } catch (err) {
                        dispatch(
                          setNotification({
                            message: err.response.data.message,
                            type: "error",
                          })
                        );
                        loadingOff();
                      }
                    };
                    return (
                      <>
                        <button
                          disabled={!selectedQuote}
                          className={btnClasses}
                        >
                          <a
                            className="text-decoration-none text-white"
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="mdi mdi-download"></i>Download Pdf
                            {!url && (
                              <div
                                className="spinner-border spinner-border-sm text-white ms-2 mb-1"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            )}
                          </a>
                        </button>
                        <button
                          disabled={!emailTo || !url}
                          onClick={sendEmail}
                          className="btn btn-primary"
                        >
                          Send Email
                        </button>
                      </>
                    );
                  }}
                </BlobProvider>
              </>
            ) : (
              <>
                <div>
                  {selectedQuote?.quoteProducts?.length === 0 ? (
                    <button className={`${btnClasses} btn-danger`}>
                      No Products
                    </button>
                  ) : !selectedQuote?.location?.pdfLogo ? (
                    <button className={`${btnClasses} btn-danger`}>
                      Please Select location in customer && <br />
                      Add Pdf Logo in location
                    </button>
                  ) : (
                    <button className={btnClasses} onClick={getLocationLogo}>
                      Generate PDF
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
