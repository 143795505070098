import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function Subscription() {
  /*   All States
   ********************************************* */

  /*   All Functions
   ********************************************* */
  const dispatch = useDispatch();

  const [subscription, setSubscription] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const getSubscriptionInfo = async () => {
    try {
      loadingOn();
      const { data } = await API.get("/companies/subscription-info", {
        headers: authHeader(),
      });

      if (data?.success) {
        setSubscription(data.subscriptionDetails);
      } else if (!data.success) {
        setMessage(data.message);
        dispatch(
          setNotification({
            message: data.message,
            type: "error",
          })
        );
      } else {
        dispatch(
          setNotification({
            message: "Failed to fetch subscription details",
            type: "error",
          })
        );
      }

      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    let timeOutID = setTimeout(() => {
      getSubscriptionInfo();
    }, 100);

    return () => {
      clearTimeout(timeOutID);
    };
  }, []);

  return (
    <div className="">
      <h3>Subscription Info</h3>
      <hr />
      <div className="">
        <div className="">
          {loading && <Spinner />}
          {!subscription && loading && !message ? (
            <div className="alert alert-info">
              checking subscription details...
            </div>
          ) : !subscription && !loading && message ? (
            <div className="alert alert-danger">
              {message}
              <br />
              Please Contact Us @ alex@gmail.com
            </div>
          ) : (
            <>
              <div className="row mb-2">
                <div className="col-md-4 font-weight-bold">Active:</div>
                <div className="col-md-8">
                  {JSON.stringify(subscription?.active)}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 font-weight-bold">State:</div>
                <div className="col-md-8">{subscription?.state}</div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 font-weight-bold">Currency:</div>
                <div className="col-md-8">{subscription?.currency}</div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 font-weight-bold">Price:</div>
                <div className="col-md-8">{subscription?.price}</div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 font-weight-bold">Product:</div>
                <div className="col-md-8">{subscription?.product}</div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 font-weight-bold">Interval Unit:</div>
                <div className="col-md-8">{subscription?.intervalUnit}</div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 font-weight-bold">
                  Interval Length:
                </div>
                <div className="col-md-8">{subscription?.intervalLength}</div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 font-weight-bold">Last Changed:</div>
                <div className="col-md-8">{subscription?.changedDisplay}</div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 font-weight-bold">
                  Next Billing Date:
                </div>
                <div className="col-md-8">{subscription?.nextDisplay}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
