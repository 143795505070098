import AttReportBtn from "components/Pdfs/Attendance/AttReportBtn";
import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function GenerateAttendance({
  deviceId,
  userData,
  holidaysList,
}) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [showModal, setShowModal] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [loading, setLoading] = useState(false);

  const [selectedReport, setSelectedReport] = useState(null);

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const handleSelectMonth = (event) => {
    setSelectedReport(null);
    setSelectedMonth(event.target.value);
  };

  /* 
  1. Sets Start and End of month 
  2. Makes Api call based on start-end month
  3. Checks if User is Night Office Member and run function accordingly
   */
  const GenerateReport = async (e) => {
    if (!selectedMonth) {
      dispatch(
        setNotification({
          message: "Please Select Month",
          type: "error",
        })
      );
      return;
    }

    if (
      !userData?.weeklySchedule ||
      Object.keys(JSON.parse(userData?.weeklySchedule)).length === 0
    ) {
      dispatch(
        setNotification({
          message: "Please Set Weekly Schedule",
          type: "error",
        })
      );
      return;
    }

    // Get Start of month
    const selectedDate = new Date(selectedMonth);
    const startOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      1
    );

    // Get End of month
    const endOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1,
      5
    );

    // Increment 2 day if userData.isNightOffice is true
    if (userData?.isNightOffice) {
      endOfMonth.setDate(endOfMonth.getDate() + 1);
    }

    // Make Api call based on start-end month
    const data = await getLogsByDate({
      startDate: startOfMonth,
      endDate: endOfMonth,
    });

    if (data?.success) {
      getParsedAttendanceRecord(data.data);
    } else {
      dispatch(
        setNotification({
          message: data?.message,
          type: "error",
        })
      );
    }
  };

  // Make Api call to get logs by selected Month
  const getLogsByDate = async ({ startDate, endDate }) => {
    try {
      loadingOn();
      const { data } = await API.post(
        "/get-user-logs/" + userData?.userId,
        {
          deviceId,
          startDate,
          endDate,
        },
        {
          headers: authHeader(),
        }
      );

      loadingOff();
      return data;
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   Day Office Functions
   ********************************************* */

  /**
   * Takes Database entries of records
   * Group records of same date.
   * Gets only records of same date
   * Also calculates OverTime, LateTime, Total Hours, TotalLateTime etc.
   * @param {*} attendanceRecords
   * @returns list of attendance records by day
   */
  const getParsedAttendanceRecord = (attendanceRecords) => {
    setSelectedReport((prev) => null);

    let recordMonth = new Date(selectedMonth);

    // Get Month-Year of selected month (eg. 08-2024) Used for getting array of All days of month
    let monthYear = `${
      recordMonth.getMonth() + 1
    }-${recordMonth.getFullYear()}`;

    const recordsByDay = {};
    /* 
    Looping Over Attendance records arr received by database
    1.Getting Date and Time from timestamp of database and custom formatting it.
    2.Appending to records by day: { day : records-of-that-day } format.
     */

    attendanceRecords.forEach((record) => {
      const date = new Date(record.recordTime);

      // Convert to local time
      const options = {
        timeZone: "Asia/Karachi",
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      const localTime = date.toLocaleString("en-GB", options);

      const formattedTime = localTime;

      // Get dd-mm-yyyy format date
      const formattedDate = `${
        date.getMonth() + 1
      }-${date.getDate()}-${date.getFullYear()}`;

      /* 
      1. Insert all Records of same date under that date
      Check if recordsByDay[day] has previous value if not that initialize that record by empty array. 
      2. Append Day Name of day. (eg: Monday, Tuesday....)
      */
      recordsByDay[formattedDate] = (recordsByDay[formattedDate] || []).concat({
        ...record,
        formattedTime,
        formattedDate,
        dayOfWeek: getDayNameByDate(date),
      });
    });

    /* 
    Since day can have multiple records. We need only In-Time and OutTime records.
    1. Looping over recordsByDay and getting only In-Out Records of each Date.
     */
    const parsedRecords = Object.keys(recordsByDay).reduce((prev, key) => {
      const records = recordsByDay[key];
      let nextDate = new Date(key);
      nextDate.setDate(nextDate.getDate() + 1);

      let formattedNextDate = `${
        nextDate.getMonth() + 1
      }-${nextDate.getDate()}-${nextDate.getFullYear()}`;

      prev[key] = userData?.isNightOffice
        ? getCalculatedAttendanceNightOfficeData(
            records,
            recordsByDay[formattedNextDate]
          )
        : getCalculatedAttendanceData(records);
      return prev;
    }, {});

    /* 
    Only get those records which are of selected month
    eg. 08-01-2024 to 08-31-2024 if there are 31 days. 
    */
    const filteredRecords = Object.entries(parsedRecords)
      .filter(([key]) => {
        const [month, day, year] = key.split("-").map(Number);
        const date = new Date(year, month - 1, day);
        return (
          date.getMonth() === recordMonth.getMonth() &&
          date.getFullYear() === recordMonth.getFullYear()
        );
      })
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

    // Check if there is any record in filteredRecords
    if (Object.keys(filteredRecords).length === 0) {
      setSelectedReport((prev) => ({
        attendanceRecords: {},
        userData,
        monthYear,
        summaryData: {
          totalLateTime: "00:00",
          totalOverTime: "00:00",
          totalWorkingHours: "00:00",
        },
      }));
      return;
    }

    const weeklySchedule = JSON.parse(userData?.weeklySchedule);

    /**
     * Check for holidays
     * holidayList  = {
          "name": "Independence Day ",
          "date": "2024-08-14",
          "id": "b677dbe2-3cf3-4c9d-acfd-e4969b29c968"
        }
         
      Add Holidays to list of attendance.
      Rules:
        Overwrite user entry if exists in holiday.
          ↘️ Calculate expected hours and add as overtime & totalTime.

      return object for each holiday = {
      inTime, // same as userInTime
      outTime, // same as userOutTime
      formattedDate,
      dayOfWeek,
      
      dayNameOfWeek,
      userInTime,
      userOutTime,
      totalExpectedHours: totalExpectedHoursInDecimal,

      totalLateTime: 0.00,  
      totalHours: , // total expected hours
      overTime: , // total expected hours
    }

      Steps: 
      Get holidays for selectedMonth.
      return object for each holiday.
     */
    const filteredHolidays = !holidaysList
      ? []
      : holidaysList.filter((holiday) => {
          const [year, month, day] = holiday.date.split("-").map(Number);
          const holidayDate = new Date(year, month - 1, day);
          return (
            holidayDate.getMonth() === recordMonth.getMonth() &&
            holidayDate.getFullYear() === recordMonth.getFullYear()
          );
        });

    filteredHolidays.forEach((holiday) => {
      const holidayDate = holiday.date;
      const [year, month, day] = holidayDate.split("-").map(Number);
      const dateObj = new Date(year, month - 1, day);
      const dayName = getDayNameByDate(dateObj);
      const daySchedule = weeklySchedule[dayName];
      let totalExpectedHoursInDecimal = 0;
      if (daySchedule?.working) {
        const totalExpectedHours = calculateTimeDifference(
          daySchedule.outTime,
          daySchedule.inTime
        );
        totalExpectedHoursInDecimal = parseFloat(
          convertToDecimalHours(totalExpectedHours).toFixed(2)
        );
      }

      let correctDateFormat = `${month}-${day}-${year}`;

      filteredRecords[correctDateFormat] = {
        type: "Holiday",
        message: "Holiday",
        inTime: "Holiday ",
        outTime: "Holiday",
        formattedDate: holidayDate,
        dayOfWeek: dayName,
        dayNameOfWeek: `${dayName} \n ${holiday.name}`,
        userInTime: daySchedule?.inTime,
        userOutTime: daySchedule?.outTime,
        totalExpectedHours: totalExpectedHoursInDecimal,
        totalLateTime: 0.0,
        totalHours: totalExpectedHoursInDecimal,
        overTime: totalExpectedHoursInDecimal,
      };
    });

    /*   Summary Section
     ********************************************* */

    /**
     * calculate totalLateTime totalOverTime totalWorkingHours
     * From filteredRecords
     **/
    const summaryData = Object.values(filteredRecords).reduce(
      (prev, curr) => {
        if (Object.keys(curr).length === 0) {
          return prev;
        }

        // Add totalLateTime totalOverTime totalWorkingHours
        prev.totalLateTime = curr.totalLateTime
          ? parseFloat((prev.totalLateTime + curr.totalLateTime).toFixed(2))
          : prev.totalLateTime;

        prev.totalOverTime = curr.overTime
          ? parseFloat((prev.totalOverTime + curr.overTime).toFixed(2))
          : prev.totalOverTime;
        prev.totalWorkingHours = curr.totalHours
          ? parseFloat((prev.totalWorkingHours + curr.totalHours).toFixed(2))
          : prev.totalWorkingHours;

        return prev;
      },
      {
        totalLateTime: 0.0,
        totalOverTime: 0.0,
        totalWorkingHours: 0.0,
      }
    );

    let parsedSummaryData = {
      totalLateTime: summaryData.totalLateTime,
      totalOverTime: summaryData.totalOverTime,
      totalWorkingHours: summaryData.totalWorkingHours,
    };

    /** ===== Get Absent Days and Holidays =====
     *
     * Goes through all months date.
     * Gets daySchedule of each day.
     * Checks if its working day and are no records then marks them as absent
     * If working is false then marks them as holidays
     */

    // Getting All dates of month
    let allMonthDates = getDatesArrByMonth(monthYear);
    let absentDaysCount = 0;

    allMonthDates.forEach((date) => {
      // Get Day name for the current day
      let dateObj = new Date(date);
      let dayName = getDayNameByDate(dateObj);

      // get weeklySchedule Object of current day
      let daySchedule = weeklySchedule[dayName];

      // Check record for that day is marked as holiday then return
      if (filteredRecords[date]?.type === "Holiday") {
        return;
      }

      /**
       * if daySchedule?.working === false then add message property that day as holiday
       * if daySchedule?.working === true and there are no records for that day then add message property that day as absent
       */
      if (!filteredRecords[date] && daySchedule?.working === false) {
        filteredRecords[date] = { message: "Holiday" };
      } else if (
        (!filteredRecords[date] && daySchedule?.working === true) ||
        filteredRecords[date]?.message === "absent"
      ) {
        filteredRecords[date] = { message: "Absent" };
        absentDaysCount++;
      }
    });
    parsedSummaryData.absentDaysCount = absentDaysCount;

    console.log(filteredRecords);

    console.log({
      attendanceRecords: filteredRecords,
      userData,
      monthYear,
      absentDaysCount,
      summaryData: parsedSummaryData,
    });

    setSelectedReport({
      attendanceRecords: filteredRecords,
      userData,
      monthYear,
      absentDaysCount,
      summaryData: parsedSummaryData,
    });

    return {
      attendanceRecords: filteredRecords,
      userData,
      monthYear,
      absentDaysCount,
      summaryData: parsedSummaryData,
    };
  };

  /**
   * Calculate In-out total-hours total overtime etc.
   * @param {Array of recordsByDay} arr
   * @returns Object With In-Out Time, Over-late time of user.
   *
   */
  const getCalculatedAttendanceData = (arr) => {
    const sortedArr = arr.sort(
      (a, b) => new Date(a.recordTime) - new Date(b.recordTime)
    );

    let userRules = JSON.parse(userData?.rules);

    /* 
    userData = {
    "id": 3,
    "companyId": 3,
    "userId": 3,
    "deviceId": 1,
    "name": "TARIQ",
    "password": "",
    "role": "14",
    "cardno": "4634703",
    "userName": "Tariq Tahir",
    "badgeNo": "007",
    "relaxationMinutes": 15,
    "departmentId": null,
    "weeklySchedule": "{\"Monday\":{\"inTime\":\"10:00\",\"outTime\":\"23:00\",\"working\":true},\"Tuesday\":{\"inTime\":\"10:00\",\"outTime\":\"23:00\",\"working\":true},\"Wednesday\":{\"inTime\":\"10:00\",\"outTime\":\"23:00\",\"working\":true},\"Thursday\":{\"inTime\":\"10:00\",\"outTime\":\"23:00\",\"working\":true},\"Friday\":{\"inTime\":\"10:00\",\"outTime\":\"23:00\",\"working\":true},\"Saturday\":{\"inTime\":\"\",\"outTime\":\"\",\"working\":false},\"Sunday\":{\"inTime\":\"\",\"outTime\":\"\",\"working\":false}}",
    rules:"{"overTime":{"name":"Overtime Calculation","description":"Calculate overtime after expected hours + overtimeAfterMinutes","isChecked":true},"lateTime":{"name":"Late Time Calculation","description":"Only calculate late time when inTime is greater than inTimeWithRelaxationFormatted. Count late time from userInTime when inTime is less than inTimeWithRelaxationFormatted","isChecked":true}}"
    "isNightOffice": false,
    "groupId": null,
    "createdAt": "2024-10-24T09:35:58.000Z",
    "updatedAt": "2024-11-13T07:42:02.000Z"
}
     */

    /**
     * After sorting records by Day
     * We only need 2 entries first and last entry.
     * First entry will always be inTime
     * Last entry will always be outTime
     * We also have user defined UserInTime and UserOutTime in UserData
     * We get overtime and late time comparing inTime-outTime with UserInTime-UserOutTime
     */
    const firstEntry = sortedArr.slice(0, 1);
    const lastEntry = sortedArr.slice(-1);

    const date = new Date(firstEntry[0].recordTime);
    let dayOfWeek = date.getDate();

    let formattedDate = firstEntry[0]?.formattedDate;
    let dayNameOfWeek = getDayNameByDate(date);

    /**
     * Get dayOfWeek from firstEntry (eg. Monday, Tuesday,.....)
     * Get inTime and outTime from userData.weeklySchedule[dayOfWeek]
     * Also check if userData.weeklySchedule[dayOfWeek].working === true
     */
    const weeklySchedule = JSON.parse(userData?.weeklySchedule);
    const daySchedule = weeklySchedule[dayNameOfWeek];

    let userInTime = daySchedule.inTime;
    let userOutTime = daySchedule.outTime;

    let inTime = firstEntry[0]?.formattedTime;
    let outTime = lastEntry[0]?.formattedTime;

    let relaxationMinutes = userData?.relaxationMinutes;

    /**
     * get relaxationTime by adding minutes of relaxationMinutes to inTime
     * ALso check if total minutes of adding minutes of relaxationMinutes to inTime gets over 60 min
     * Then reflect that in hour
     * eg: if userInTime = "8:30" and relaxationMinutes are 35 mins,
     * then relaxationTime = "9:05"
     */

    let relaxationTimeInMinutes = relaxationMinutes;
    let relaxationTimeInHours = Math.floor(relaxationTimeInMinutes / 60);
    let relaxationTimeInRemainingMinutes = relaxationTimeInMinutes % 60;

    let relaxationTime = `${relaxationTimeInHours}:${relaxationTimeInRemainingMinutes}`;

    let relaxationTimeObj = parseTime(relaxationTime);

    let inTimeWithRelaxation = new Date();
    inTimeWithRelaxation.setHours(
      relaxationTimeObj.hours + parseInt(userInTime.split(":")[0]),
      relaxationTimeObj.minutes + parseInt(userInTime.split(":")[1]),
      0
    );

    let inTimeWithRelaxationFormatted = `${inTimeWithRelaxation.getHours()}:${inTimeWithRelaxation.getMinutes()}:${inTimeWithRelaxation.getSeconds()}`;

    /** Rule:
     *      Only Calculate lateTime when inTime is greater than inTimeWithRelaxationFormatted
     *      Count lateTime from userInTime when inTime is less than inTimeWithRelaxationFormatted
     * Calculate Total Late
     * Calculate lateTime by comparing inTimeWithRelaxationFormatted with inTime.
     * If inTime is greater than inTimeWithRelaxationFormatted than
     * calculate by comparing with userInTime and inTime
     */
    let totalLateTime;
    if (userRules && userRules["lateTime"]?.isChecked) {
      totalLateTime = isFirstInputEarlier(inTime, inTimeWithRelaxationFormatted)
        ? { hours: 0, minutes: 0, seconds: 0 }
        : calculateLateHours(userInTime, inTime);
    } else {
      totalLateTime = calculateLateHours(userInTime, inTime);
    }

    // Total Hours
    let totalHoursObj = calculateTimeDifference(outTime, inTime);

    /*   Decimal Hours
     ********************************************* */
    let totalLateTimeValue =
      Math.sign(totalLateTime.hours) === -1
        ? 0
        : parseFloat(convertToDecimalHours(totalLateTime).toFixed(2));

    let totalHoursValue = parseFloat(
      convertToDecimalHours(totalHoursObj).toFixed(2)
    );

    /**
     * Calculate Total Expected Hours
     * And find difference between expected and worked hours
     */

    let totalExpectedHours = calculateTimeDifference(userOutTime, userInTime);

    // Calculate OverTime when totalHoursObj are more than totalExpectedHours
    let totalExpectedHoursInDecimal = parseFloat(
      convertToDecimalHours(totalExpectedHours).toFixed(2)
    );

    /**  Rule:
     *        Calculate Overtime AFter Expected Hours + overtimeAfterMinutes
     * Add overtimeAfterMinutes to totalExpectedHoursInDecimal SO to calculate overtime
     * after expected hours + overtimeAfterMinutes
     */
    if (userRules && userRules["overTime"]?.isChecked) {
      totalExpectedHoursInDecimal += userData?.overtimeAfterMinutes / 60;
    }
    let overTimeValue = 0.0;
    if (totalHoursValue > totalExpectedHoursInDecimal) {
      overTimeValue = parseFloat(
        totalHoursValue - totalExpectedHoursInDecimal
      ).toFixed(2);
    }

    return {
      inTime,
      outTime,
      formattedDate,
      dayOfWeek,
      inRecord: firstEntry[0].recordTime,
      outRecord: lastEntry[0].recordTime,
      dayNameOfWeek,
      userInTime,
      userOutTime,
      totalExpectedHours: totalExpectedHoursInDecimal,

      totalLateTime: inTime === outTime ? 0.0 : parseFloat(totalLateTimeValue),
      totalHours: inTime === outTime ? 0.0 : parseFloat(totalHoursValue),
      overTime: inTime === outTime ? 0.0 : parseFloat(overTimeValue),
    };
  };

  /*   Night Office Functions
   ********************************************* */

  //  Calculate In-out total-hours total overtime etc.
  const getCalculatedAttendanceNightOfficeData = (
    curDateRecords,
    nextDateRecords
  ) => {
    /**
     * Check if absent on day.
     * When absent there will be only one entry in curDateRecords and working for that day will be true
     */
    if (curDateRecords.length === 1) {
      let timeOfCurrDate = curDateRecords[0].formattedTime;
      let checkAmOrPm = parseTime(timeOfCurrDate).hours > 12 ? "PM" : "AM";
      let weeklySchedule = JSON.parse(userData?.weeklySchedule);
      let daySchedule = weeklySchedule[curDateRecords[0].dayOfWeek];
      if (daySchedule?.working && checkAmOrPm === "AM") {
        return {
          message: "absent",
        };
      }
      if (!daySchedule?.working && checkAmOrPm === "AM") {
        return { message: "Holiday" };
      }
    }

    let userRules = JSON.parse(userData?.rules);

    /* 
    userData = {
    "id": 3,
    "companyId": 3,
    "userId": 3,
    "deviceId": 1,
    "name": "TARIQ",
    "password": "",
    "role": "14",
    "cardno": "4634703",
    "userName": "Tariq Tahir",
    "badgeNo": "007",
    "relaxationMinutes": 15,
    "departmentId": null,
    "weeklySchedule": "{\"Monday\":{\"inTime\":\"10:00\",\"outTime\":\"23:00\",\"working\":true},\"Tuesday\":{\"inTime\":\"10:00\",\"outTime\":\"23:00\",\"working\":true},\"Wednesday\":{\"inTime\":\"10:00\",\"outTime\":\"23:00\",\"working\":true},\"Thursday\":{\"inTime\":\"10:00\",\"outTime\":\"23:00\",\"working\":true},\"Friday\":{\"inTime\":\"10:00\",\"outTime\":\"23:00\",\"working\":true},\"Saturday\":{\"inTime\":\"\",\"outTime\":\"\",\"working\":false},\"Sunday\":{\"inTime\":\"\",\"outTime\":\"\",\"working\":false}}",
    rules:"{"overTime":{"name":"Overtime Calculation","description":"Calculate overtime after expected hours + overtimeAfterMinutes","isChecked":true},"lateTime":{"name":"Late Time Calculation","description":"Only calculate late time when inTime is greater than inTimeWithRelaxationFormatted. Count late time from userInTime when inTime is less than inTimeWithRelaxationFormatted","isChecked":true}}"
    "isNightOffice": false,
    "groupId": null,
    "createdAt": "2024-10-24T09:35:58.000Z",
    "updatedAt": "2024-11-13T07:42:02.000Z"
}
     */

    if (!nextDateRecords) {
      return {};
    }

    const sortedCurrDateRecords = curDateRecords.sort(
      (a, b) => new Date(a.recordTime) - new Date(b.recordTime)
    );
    const sortedNextDateRecords = nextDateRecords.sort(
      (a, b) => new Date(a.recordTime) - new Date(b.recordTime)
    );

    /**
     * After sorting records by Day
     * We only need 2 entries first and last entry.
     * First entry will always be OutTime of previous Day
     * Last entry will always be InTime of recordDate
     * We also have user defined UserInTime and UserOutTime in UserData
     * We get overtime and late time comparing inTime-outTime with UserInTime-UserOutTime
     */

    const firstEntry = sortedCurrDateRecords.slice(-1);
    const lastEntry = sortedNextDateRecords.slice(0, 1);

    const date = new Date(firstEntry[0].recordTime);
    let dayOfWeek = date.getDate();

    let formattedDate = firstEntry[0]?.formattedDate;
    let dayNameOfWeek = getDayNameByDate(date);

    /**
     * Get dayOfWeek from firstEntry (eg. Monday, Tuesday,.....)
     * Get inTime and outTime from userData.weeklySchedule[dayOfWeek]
     * Also check if userData.weeklySchedule[dayOfWeek].working === true
     */
    const weeklySchedule = JSON.parse(userData?.weeklySchedule);
    const daySchedule = weeklySchedule[dayNameOfWeek];

    let userInTime = daySchedule.inTime;
    let userOutTime = daySchedule.outTime;

    let inTime = firstEntry[0]?.formattedTime;
    let outTime = lastEntry[0]?.formattedTime;

    let relaxationMinutes = userData?.relaxationMinutes;

    /**
     * get relaxationTime by adding minutes of relaxationMinutes to inTime
     * ALso check if total minutes of adding minutes of relaxationMinutes to inTime gets over 60 min
     * Then reflect that in hour
     * eg: if userInTime = "8:30" and relaxationMinutes are 35 mins,
     * then relaxationTime = "9:05"
     */

    let relaxationTimeInMinutes = relaxationMinutes;
    let relaxationTimeInHours = Math.floor(relaxationTimeInMinutes / 60);
    let relaxationTimeInRemainingMinutes = relaxationTimeInMinutes % 60;

    let relaxationTime = `${relaxationTimeInHours}:${relaxationTimeInRemainingMinutes}`;

    let relaxationTimeObj = parseTime(relaxationTime);

    let inTimeWithRelaxation = new Date();
    inTimeWithRelaxation.setHours(
      relaxationTimeObj.hours + parseInt(userInTime.split(":")[0]),
      relaxationTimeObj.minutes + parseInt(userInTime.split(":")[1]),
      0
    );

    let inTimeWithRelaxationFormatted = `${inTimeWithRelaxation.getHours()}:${inTimeWithRelaxation.getMinutes()}`;

    /* =================== Get Total Hours */

    // Get hours after endOfDay of outTime
    const extraHoursAfter12 = calculateHoursWorked("00:00", outTime);

    let userInTimeObj = parseTime(userInTime);
    let inTimeObj = parseTime(inTime);

    let inTimeForOverTime =
      inTimeObj.hours < userInTimeObj.hours ? userInTime : inTime;

    // Get starting Hours of inTime and End of Day
    const startingHours = calculateTimeDifferenceToEndOfDay(inTimeForOverTime);

    // Combine startingHours and extraHoursAfter12
    const totalHours = {
      hours: startingHours.hours + extraHoursAfter12.hours,
      minutes: startingHours.minutes + extraHoursAfter12.minutes,
    };

    let totalHoursObj = parseTime(`${totalHours.hours}:${totalHours.minutes}`);

    let totalHoursValue = parseFloat(
      convertToDecimalHours(totalHoursObj).toFixed(2)
    );

    /* =================== Get OverTime */
    /** Conditions
     * if User is early then User's userInTime. Then Calculate from User's userInTime
     * if User is late than User's userInTime. Then calculate from User's current Date inTime.
     */

    // Get starting Hours of inTime and End of Day
    const expectedStartingHours =
      calculateTimeDifferenceToEndOfDay(inTimeForOverTime);

    // Get hours after endOfDay of outTime
    const extraExpectedHoursAfter12 = calculateHoursWorked(
      "00:00:00",
      userOutTime
    );

    // Combine startingHours and extraHoursAfter12
    const totalExpectedHours = {
      hours: expectedStartingHours.hours + extraExpectedHoursAfter12.hours,
      minutes:
        expectedStartingHours.minutes + extraExpectedHoursAfter12.minutes,
    };

    // console.log(totalExpectedHours);

    let totalExpectedHoursObj = parseTime(
      `${totalExpectedHours.hours}:${totalExpectedHours.minutes}`
    );

    let totalExpectedHoursInDecimal = parseFloat(
      convertToDecimalHours(totalExpectedHoursObj).toFixed(2)
    );

    /**  Rule:
     *        Calculate Overtime AFter Expected Hours + overtimeAfterMinutes
     * Add overtimeAfterMinutes to totalExpectedHoursInDecimal SO to calculate overtime
     * after expected hours + overtimeAfterMinutes
     */
    if (userRules && userRules["overTime"]?.isChecked) {
      totalExpectedHoursInDecimal += userData?.overtimeAfterMinutes / 60;
    }

    let overTimeValue = 0.0;
    if (totalHoursValue > totalExpectedHoursInDecimal) {
      overTimeValue = parseFloat(
        totalHoursValue - totalExpectedHoursInDecimal
      ).toFixed(2);
    }

    // let overTimeValue = 0.0;
    // if (totalHoursValue > totalExpectedHoursInDecimal) {
    //   overTimeValue = parseFloat(
    //     totalHoursValue - totalExpectedHoursInDecimal
    //   ).toFixed(2);
    // }

    /* =================== Get Late Time */

    /** Rule:
     *      Only Calculate lateTime when inTime is greater than inTimeWithRelaxationFormatted
     *      Count lateTime from userInTime when inTime is less than inTimeWithRelaxationFormatted
     * Calculate Total Late
     * Calculate lateTime by comparing inTimeWithRelaxationFormatted with inTime.
     * If inTime is greater than inTimeWithRelaxationFormatted than
     * calculate by comparing with userInTime and inTime
     */

    let totalLateTime;
    if (userRules && userRules["lateTime"]?.isChecked) {
      totalLateTime = isFirstInputEarlier(inTime, inTimeWithRelaxationFormatted)
        ? { hours: 0, minutes: 0, seconds: 0 }
        : calculateLateHours(userInTime, inTime);
    } else {
      totalLateTime = calculateLateHours(userInTime, inTime);
    }

    /*   Decimal Hours
     ********************************************* */
    let totalLateTimeValue =
      Math.sign(totalLateTime.hours) === -1
        ? 0
        : parseFloat(convertToDecimalHours(totalLateTime).toFixed(2));

    return {
      inTime,
      outTime,
      formattedDate,
      dayOfWeek,
      inRecord: firstEntry[0].recordTime,
      outRecord: lastEntry[0].recordTime,
      dayNameOfWeek,
      userInTime,
      userOutTime,
      totalExpectedHours: totalExpectedHoursInDecimal,
      totalLateTime: inTime === outTime ? 0.0 : parseFloat(totalLateTimeValue),
      totalHours: inTime === outTime ? 0.0 : parseFloat(totalHoursValue),
      overTime: inTime === outTime ? 0.0 : parseFloat(overTimeValue),
    };
  };

  /*   Common Functions
   ********************************************* */
  /** * Compares two time strings in "HH:MM" format.
   * @param {string} t1 - The first time string.
   * @param {string} t2 - The second time string.
   * @returns {boolean} True if t1 is earlier than t2, false otherwise.
   * */
  function isFirstInputEarlier(t1, t2) {
    const [hours1, minutes1] = t1.split(":").map(Number);
    const [hours2, minutes2] = t2.split(":").map(Number);
    // Compare hours first
    if (hours1 < hours2) {
      return true;
    } else if (hours1 > hours2) {
      return false;
    } else {
      // If hours are equal, compare minutes
      return minutes1 < minutes2;
    }
  }

  /**
   * @param {month-year} value eg:08-2024
   * @returns array of dates of that month
   */
  function getDatesArrByMonth(value) {
    if (!value) {
      return;
    }
    const [month, year] = value?.split("-").map(Number);
    const date = new Date(year, month - 1, 1);
    const dates = [];
    while (date.getMonth() === month - 1) {
      const day = date.getDate();
      dates.push(`${month}-${day}-${year}`);
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  /**
   * Calculates the difference in hours and minutes from a given time to the end of the day.
   * @param {string} timeString - The time string in "HH:MM" format.
   * @returns {Object} An object containing the difference in hours and minutes.
   */
  function calculateTimeDifferenceToEndOfDay(timeString) {
    // Parse the input time string
    const [inputHours, inputMinutes] = timeString.split(":").map(Number);

    // Create Date objects for the input time and end of the day
    const currentTime = new Date();
    currentTime.setHours(inputHours, inputMinutes, 0, 0); // Ensure seconds and milliseconds are set to 0

    const endOfDay = new Date(currentTime);
    endOfDay.setHours(24, 0, 0, 0); // Set to midnight of the next day

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = endOfDay - currentTime;
    const millisecondsInHour = 1000 * 60 * 60;
    const millisecondsInMinute = 1000 * 60;

    // Calculate hours and minutes from the difference
    const hours = Math.floor(differenceInMilliseconds / millisecondsInHour);
    const minutes = Math.floor(
      (differenceInMilliseconds % millisecondsInHour) / millisecondsInMinute
    );

    return { hours, minutes };
  }

  function calculateHoursWorked(inTime, outTime) {
    const t1 = parseTime(inTime);
    const t2 = parseTime(outTime);

    const startDate = new Date();
    const endDate = new Date();

    startDate.setHours(t1.hours, t1.minutes, t1.seconds);
    endDate.setHours(t2.hours, t2.minutes, t2.seconds);

    const differenceInMilliseconds = endDate - startDate;

    const millisecondsInHour = 1000 * 60 * 60;
    const millisecondsInMinute = 1000 * 60;
    const millisecondsInSecond = 1000;

    const hours =
      t1.hours === t2.hours
        ? 0
        : Math.floor(differenceInMilliseconds / millisecondsInHour);
    // const hours = Math.floor(differenceInMilliseconds / millisecondsInHour);
    const minutes = Math.floor(
      (differenceInMilliseconds % millisecondsInHour) / millisecondsInMinute
    );
    const seconds = Math.floor(
      (differenceInMilliseconds % millisecondsInMinute) / millisecondsInSecond
    );

    return { hours, minutes, seconds };
  }

  function calculateLateHours(relaxationTime, inTime) {
    const t1 = parseTime(relaxationTime);
    const t2 = parseTime(inTime);

    const startDate = new Date();
    const endDate = new Date();

    startDate.setHours(t1.hours, t1.minutes, t1.seconds);
    endDate.setHours(t2.hours, t2.minutes, t2.seconds);

    const differenceInMilliseconds = endDate - startDate;

    const millisecondsInHour = 1000 * 60 * 60;
    const millisecondsInMinute = 1000 * 60;
    const millisecondsInSecond = 1000;

    const hours =
      t1.hours === t2.hours || t2.hours < t1.hours
        ? 0
        : Math.floor(differenceInMilliseconds / millisecondsInHour);
    // const hours = Math.floor(differenceInMilliseconds / millisecondsInHour);
    const minutes =
      t1.hours === t2.minutes || t2.minutes < t1.minutes
        ? 0
        : Math.floor(
            (differenceInMilliseconds % millisecondsInHour) /
              millisecondsInMinute
          );
    const seconds = Math.floor(
      (differenceInMilliseconds % millisecondsInMinute) / millisecondsInSecond
    );

    return { hours, minutes, seconds };
  }

  /** * Gets the name of the day from a given date string. * @param {string} dateString - The date string (e.g., "11-14-2024"). * @returns {string} The name of the day (e.g., "Thursday"). */
  function getDayNameByDate(dateString) {
    const date = new Date(dateString);
    const options = { weekday: "long" }; // Options to format the day name
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  /**
   * Parses a time string in the format "HH:MM:SS" into an object with hours, minutes, and seconds.
   * If seconds are not provided, they default to 0.
   * @param {string} timeString - The time string to parse.
   * @returns {Object} An object containing hours, minutes, and seconds.
   */
  function parseTime(timeString) {
    const [hours, minutes, seconds = 0] = timeString.split(":").map(Number);
    return { hours, minutes, seconds };
  }

  /**
   * Calculates the time difference between two time strings.
   * If the second time is later than the first, the result is negative.
   * @param {string} time1 - The first time string.
   * @param {string} time2 - The second time string.
   * @returns {Object} An object containing the difference in hours, minutes, and seconds.
   */
  function calculateTimeDifference(time1, time2) {
    const t1 = parseTime(time1); // Parse the first time
    const t2 = parseTime(time2); // Parse the second time
    const date1 = new Date(); // Create a Date object for the first time
    const date2 = new Date(); // Create a Date object for the second time

    // Set the hours, minutes, and seconds for both Date objects
    date1.setHours(t1.hours, t1.minutes, t1.seconds);
    date2.setHours(t2.hours, t2.minutes, t2.seconds);

    // Calculate the difference in milliseconds between the two times
    const differenceInMilliseconds = date1 - date2;
    const millisecondsInHour = 1000 * 60 * 60; // Number of milliseconds in an hour
    const millisecondsInMinute = 1000 * 60; // Number of milliseconds in a minute
    const millisecondsInSecond = 1000; // Number of milliseconds in a second

    // Determine if the difference is negative
    const isNegative = differenceInMilliseconds < 0;
    // Calculate the absolute difference in milliseconds
    const absoluteDifference = Math.abs(differenceInMilliseconds);

    // Calculate the hours, minutes, and seconds from the absolute difference
    const hours = Math.floor(absoluteDifference / millisecondsInHour);
    let minutes = Math.floor(
      (absoluteDifference % millisecondsInHour) / millisecondsInMinute
    );
    const seconds = Math.floor(
      (absoluteDifference % millisecondsInMinute) / millisecondsInSecond
    );

    // Apply the negative sign to the hours and minutes if the difference is negative
    if (isNegative) {
      return { hours: -hours, minutes: -(minutes + 1), seconds };
    }

    return { hours, minutes: minutes, seconds };
  }

  /** * Converts a time object with hours and minutes into decimal hours.
   * @param {Object} timeObj - An object containing hours and minutes.
   * @param {number} timeObj.hours - The number of hours.
   *  @param {number} timeObj.minutes - The number of minutes.
   * @returns {number} The time in decimal hours.
   * **/
  function convertToDecimalHours(timeObj) {
    const { hours, minutes } = timeObj;
    // Destructure hours and minutes from the time object
    const decimalHours = hours + minutes / 60; // Convert minutes to decimal and add to hours
    let isNegative =
      Math.sign(hours) === -1 || Math.sign(minutes) === -1 ? true : false;
    return isNegative ? decimalHours * -1 : decimalHours;
  }

  /*   All UseEffects
   ********************************************* */

  return (
    <>
      <button
        onClick={(e) => {
          setShowModal(true);
        }}
        className="btn btn-info ms-1"
      >
        Generate Attendance
      </button>
      <Modal
        contentClassName="bg-transparent"
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Body className="bg-white custom-border-radius px-4">
          <div>
            {loading && <Spinner />}
            <h3 className="mb-3">Generate Attendance Report</h3>
            <div>
              <label className="form-label">Select Month</label>
              <input
                className="form-control"
                type="month"
                value={selectedMonth || ""}
                onChange={handleSelectMonth}
              />
            </div>
            <div className="text-end mt-3">
              <button
                onClick={(e) => {
                  setShowModal(false);
                }}
                className="btn btn-sm btn-secondary"
              >
                Close
              </button>
              <button
                onClick={GenerateReport}
                className="btn btn-sm btn-info mx-1 "
              >
                Get Records
              </button>
              {selectedReport && (
                <AttReportBtn
                  selectedReport={selectedReport}
                  btnClasses={"btn btn-primary btn-sm"}
                />
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
