import API from "../../services/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import authHeader from "services/auth-header";

export const userLogin = createAsyncThunk(
  "auth/login",
  async ({ email, password, cid, type, companyId }, { rejectWithValue }) => {
    try {
      const { data } = await API.post(`/auth/login`, {
        email,
        password,
        cid,
        type,
        companyId,
      });

      // store user's token in local storage
      if (data.accessToken) {
        localStorage.setItem("user", JSON.stringify(data));
      }

      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const registerUser = createAsyncThunk(
  "auth/register",
  async (userFields, { rejectWithValue }) => {
    try {
      const { data } = await API.post(`/auth/register`, userFields);

      // // store user's token in local storage
      // if (data.accessToken) {
      //   localStorage.setItem("user", JSON.stringify(data));
      // }

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "auth/forgotpassword",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await API.post(`/auth/forgotpassword`, body);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/password/reset",
  async (formData, { rejectWithValue }) => {
    try {
      const { data } = await API.patch(`/auth/resetpassword`, formData);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "user/getUserDetails",
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { user } = getState();

      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${user.userToken}`,
        },
      };

      const { data } = await API.get(`/user/profile`, config);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const settingsUpdate = createAsyncThunk(
  "user/update",
  async (formData, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const userToken = localStorage.getItem("userToken")
        ? localStorage.getItem("userToken")
        : null;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };

      const { data } = await API.patch(`/employee/update`, formData, config);

      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//  ================  Admin User Methods  =============================
export const addUser = createAsyncThunk(
  "user/add",
  async (formData, { rejectWithValue }) => {
    try {
      const { data } = await API.post(
        `/users/add`,
        { ...formData, role: "user" },
        {
          headers: authHeader(),
        }
      );

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editUser = createAsyncThunk(
  "user/edit",
  async (actionData, { rejectWithValue }) => {
    try {
      const { data } = await API.post(
        `/users/update/${actionData.userId}`,
        {
          ...actionData.formData,
        },
        {
          headers: authHeader(),
        }
      );

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/delete",
  async (formData, { rejectWithValue }) => {
    try {
      const { data } = await API.post(`/users/delete`, formData, {
        headers: authHeader(),
      });

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//  ================  Reset Functions below  =============================

// sending email with reset Code
export const SendResetEmail = createAsyncThunk(
  "user/SendResetEmail",
  async ({ email }, { rejectWithValue }) => {
    try {
      const { data } = await API.post(
        `/auth/sendcode`,
        { email },
        { headers: authHeader() }
      );

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// Verifying reset Code
export const VerifyResetCode = createAsyncThunk(
  "user/VerifyResetCode",
  async ({ userEmail, code }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await API.post(
        `/user/verifyCode`,
        { email: userEmail, code },
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// Updating password
export const UpdateUserPassword = createAsyncThunk(
  "user/UpdateUserPassword",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await API.post(
        `/user/resetpassword`,
        { email, password },
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
